import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Btn1One, medWrapper } from "../../styles/helpers"

const LinksButtons = ({ data }) => {
  return (
    <StyledDiv>
      <div className="wrapper">
        <div className="links">
          <Link to={`/${data.bottomLinkButtonsSlugOne}`}>
            {data.bottomLinkButtonsTextOne}
          </Link>
          <Link to={`/${data.bottomLinkButtonsSlugTwo}`}>
            {data.bottomLinkButtonsTextTwo}
          </Link>
        </div>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  margin-top: 2rem;
  margin-bottom: 5rem;
  .wrapper {
    ${medWrapper};
  }

  .links {
    width: 100%;

    @media (min-width: 768px) {
      text-align: center;
    }

    a {
      ${Btn1One};
      margin-bottom: 2.5rem;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &:first-of-type {
        margin-right: 3rem;
      }
    }
  }
`

export default LinksButtons
