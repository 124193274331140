import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/espp/Hero"
import Intro from "../components/espp/Intro"
import Video from "../components/espp/Video"
import MainContent from "../components/espp/MainContent"
import LinksButtons from "../components/espp/LinksButtons"

const espp = props => {
  const hero = props?.data?.hero?.template?.aboutEspp
  const intro = props?.data?.intro?.template?.aboutEspp
  const video = props?.data?.video?.template?.aboutEspp
  const mainContent = props?.data?.mainContent?.template?.aboutEspp
  const linksButtons = props?.data?.linksButtons?.template?.aboutEspp
  return (
    <Layout>
      <Seo title="About ESPP" />
      <Hero data={hero} />
      <Intro data={intro} />
      <Video data={video} />
      <MainContent data={mainContent} />
      <LinksButtons data={linksButtons} />
    </Layout>
  )
}

export const esppQuery = graphql`
  query aboutEsppTempPage($id: String!) {
    # seoInfo: wpPage(id: { eq: $id }) {
    #   seoFields {
    #     swbThemeDescription
    #     swbThemeMetaTitle
    #     swbThemeImage {
    #       localFile {
    #         relativePath
    #       }
    #     }
    #   }
    # }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutESPP {
          templateName
          aboutEspp {
            heroImageTitle
            heroImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    intro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutESPP {
          templateName
          aboutEspp {
            introTitle
            introBigContent
            introSmallContent
            introIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }

    video: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutESPP {
          templateName
          aboutEspp {
            videoTitle
            videoEmbed
          }
        }
      }
    }

    mainContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutESPP {
          templateName
          aboutEspp {
            mainContentWysiwyg
          }
        }
      }
    }

    linksButtons: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutESPP {
          templateName
          aboutEspp {
            bottomLinkButtonsTextOne
            bottomLinkButtonsSlugOne
            bottomLinkButtonsTextTwo
            bottomLinkButtonsSlugTwo
          }
        }
      }
    }
  }
`

export default espp
